import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable,of,Subject,BehaviorSubject  } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BinanceService {
  private tradeStreamSocket: WebSocket;
  private depthStreamSocket: WebSocket;
  private tickerStreamSocket: WebSocket;

  private tradeStreamSubject = new Subject<any>();
  private depthStreamSubject = new Subject<any>();
  private tickerStreamSubject = new Subject<any>();
  private exchangeBaseUrl = environment.ExchangeAPIRUL; // Using the environment's ExchangeAPIRUL

  private currentTradeSource = new BehaviorSubject<any[]>([]);
  currentTrade$ = this.currentTradeSource.asObservable();

  constructor(private http: HttpClient) {}

  setCurrentTrade(currentTrade: any[]): void {
    this.currentTradeSource.next(currentTrade);
  }
  getKLinesData(symbol: string, interval: string): Observable<any[]> {
    const url = `${environment.binance_api}/klines`;
    const params = {
      symbol: symbol,
      interval: interval,
    };
    // Make the HTTP request and use RxJS map operator to transform the response
    return this.http.get<any[]>(url, { params }).pipe(
      map(data =>
        data.map(d => ({
          Date: d[0] ,
          Open: d[1] * 1,
          High: d[2] * 1,
          Low: d[3] * 1,
          Close: d[4] * 1,
          Volume: d[5] * 1
        }))
      )
    );
  }


  getAllSymbols(): Observable<any[]> {
    const url = `${environment.binance_api}/exchangeInfo`;
  
    // Make the HTTP request and use RxJS operators to handle response and errors
    return this.http.get<any[]>(url).pipe(
      catchError(error => {
        // Handle HTTP request error here, e.g., log the error or return a default value
        console.error('Error fetching symbols:', error);
        return of([]); // Return an empty array as a default value in case of an error
      }),
      map((data:any) =>
        (data && data.symbols) ? data.symbols
          .filter(symbol => symbol.status === 'TRADING') // Filter symbols with status 'TRADING'
          .map(symbol => ({
            value: symbol.symbol,
            label: symbol.symbol,
            status: symbol.status,
            base_assets: symbol.baseAsset,
            quote_asset: symbol.quoteAsset
          })) : []
      )
    );
  }

  getRecentTrades(symbol: string, limit: number): Observable<any[]> {
    const url = `${environment.binance_api}/trades`;
    const params = {
      symbol: symbol,
      limit: limit.toString(),
    };
    return this.http.get<any[]>(url, { params });
  }

  connectToTradeStreams(symbol:string): Observable<any> {
    this.tradeStreamSocket = new WebSocket(environment.binance_trade+symbol+'@aggTrade');
    this.tradeStreamSocket.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      this.tradeStreamSubject.next(parsedData);
    };
    return new Observable<any>((observer) => {
      this.tradeStreamSubject.subscribe({
        next: (data) => observer.next({ type: 'trade', data }),
        error: (error) => observer.error(error),
        complete: () => console.log('Trade Stream Error', Error),
      });

      this.tradeStreamSocket.onerror = (error) => observer.error(error);

      return () => {
        this.tradeStreamSocket.close();
      };
    });
  }

  connectToDepthStreams(symbol:string): Observable<any> {
    this.depthStreamSocket = new WebSocket(environment.binance_depth+symbol+'@depth20@100ms');    
    this.depthStreamSocket.onmessage = (event) => {
      this.depthStreamSubject.next(event.data);
    };
    return new Observable<any>((observer) => {
      this.depthStreamSubject.subscribe({
        next: (data) => observer.next({ type: 'depth', data }),
        error: (error) => observer.error(error),
        complete: () => console.log('Depth Stream Error', Error),
      });

      this.depthStreamSocket.onerror = (error) => observer.error(error);

      return () => {
        this.depthStreamSocket.close();
      };
    });
  }
  connectToTickerStreams(symbol:string): Observable<any> {
    this.tickerStreamSocket = new WebSocket(environment.binance_ticker+symbol+'@ticker');

    this.tickerStreamSocket.onmessage = (event) => {
      this.tickerStreamSubject.next(event.data);
    };

    return new Observable<any>((observer) => {

      this.tickerStreamSubject.subscribe({
        next: (data) => observer.next({ type: 'ticker', data }),
        error: (error) => observer.error(error),
        complete: () => console.log('Ticker Stream Error', Error),
      });

      this.tickerStreamSocket.onerror = (error) => observer.error(error);

      return () => {
        this.tickerStreamSocket.close();
      };
    });
  }
}
